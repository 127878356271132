/* EduShop.css */
.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 16px;
    padding: 16px;
    direction: rtl; /* برای راست چین کردن کل گرید */
}

.product-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* سایه نازک */
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    text-align: right;
    padding: 10px;
}

.product-image {
    width: 100%;
    max-width: 350px;
    height: 220px; /* تنظیم ارتفاع ثابت */
    object-fit: cover; /* برای تنظیم تصاویر بزرگ */
    display: block;
    margin: 0 auto 16px;
}

.bNazanintdrD {
    text-align: justify; /* متن justify */
}

.bNazanintdrD4 {
    text-align: justify; /* متن justify */
    color: var(--clr-fg-alt);
}

.bNazanintdrDA {
    font-family: vazir;
    font-size: 12px;
    color: var(--clr-fg-alt);
    text-align: justify; /* متن justify */
    direction: rtl;      /* تنظیم جهت متن به سمت راست */
}

.more-button {
    color: #a9a9a9; /* رنگ خاکستری کمرنگ برای دکمه ... more */
    cursor: pointer;
    text-align: left;
    display: block;
    margin-top: 8px;
    font-size: 0.8rem; /* کاهش اندازه فونت */
    padding: 2px 5px;
}

.notification-rtl {
    direction: rtl; /* راست چین کردن نوتیفیکیشن */
    font-family: 'bNazanin', sans-serif; /* تنظیم فونت نوتیفیکیشن */
}


/* EduShop.css */
.notification-rtl {
    direction: rtl; /* راست چین کردن نوتیفیکیشن */
    font-family: 'bNazanin', sans-serif; /* تنظیم فونت نوتیفیکیشن */
}

.price {
    float: left; /* چپ‌چین کردن متن */
    margin-left: 10px; /* فاصله‌گذاری اختیاری برای زیبایی بیشتر */
    font-size: 0.7rem; /* کاهش اندازه فونت */
    color: rgba(0, 0, 0, 0.47); /* کاهش شدت رنگ */
}

.vertical-bar {
    font-weight: bold; /* برجسته کردن */
    font-size: 1rem; /* کاهش اندازه فونت برای کوچک‌تر شدن ارتفاع */
    color: rgba(0, 0, 0, 0.3); /* کمرنگ کردن */
}

.product-actions {
    padding-top: 20px;
}

.search-container {
    display: flex;
    justify-content: flex-end; /* Right align the search box */
    margin: 20px;
}

.search-input {
    padding: 8px;
    width: 100%; /* Responsive width */
    max-width: 320px;
    font-size: 16px;
    text-align: right;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-family: 'bNazanin', sans-serif; /* تغییر فونت */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .search-container {
        justify-content: center; /* Center align the search box on mobile */
    }
}