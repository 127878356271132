/* ScrollToTopMobile.css */
.scroll-to-top {
    position: fixed;
    bottom: 55px; /* این مقدار را به دلخواه خود تنظیم کنید */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 24px;
    background-color: #ff8c00; /* رنگ پس‌زمینه دلخواه */
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.scroll-to-top:hover {
    background-color: #e58900; /* رنگ پس‌زمینه در حالت هاور */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
