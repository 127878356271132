/* ProductDetail.css */
.product-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
}

.product-detail .ant-card {
    width: 600px;
    text-align: right;
}

.product-detail img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
