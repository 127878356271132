.signup-container {
    direction: rtl; /* برای راست‌چین کردن */
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
    max-width: 400px;
    margin: 50px auto;
    padding: 0px;
    text-align: right;
    display: flex; /* استفاده از Flexbox */
    flex-direction: column; /* چیدمان به صورت عمودی */
}

.signup-title {
    margin-bottom: 20px;
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
    text-align: right;
}

.signup-container .ant-form-item {
    text-align: right; /* راست‌چین کردن محتوا */
}

.signup-container .ant-input {
    direction: rtl; /* راست‌چین کردن پیش‌فرض ورودی‌ها */
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
}

.signup-container .phone-input::placeholder {
    text-align: right; /* راست‌چین کردن متن پیش‌فرض فیلد شماره تلفن */
}

.signup-container .phone-input {
    direction: ltr; /* چپ‌چین کردن متن وارد شده در فیلد شماره تلفن */
    text-align: left; /* چپ‌چین کردن متن وارد شده */
}

.signup-container .signup-btn {
    display: block; /* نمایش دکمه به صورت بلوک */
    margin: 0 auto; /* قرار دادن دکمه در مرکز */
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
}

.signup-container .phone-input::placeholder, .signup-container .nationalcode-input::placeholder, .signup-container .email-input::placeholder {
    text-align: right; /* راست‌چین کردن متن پیش‌فرض فیلدهای شماره تلفن و کد ملی */
}

.signup-container .phone-input, .signup-container .nationalcode-input, .signup-container .email-input {
    direction: ltr; /* چپ‌چین کردن متن وارد شده در فیلدهای شماره تلفن و کد ملی */
    text-align: left; /* چپ‌چین کردن متن وارد شده */
}

/* Signup.css */
.signup-btn-container {
    display: flex;
    justify-content: center;
}