.product-container {
    display: flex;
}

.product-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; /* تکمیل از سمت راست در حالت دسکتاپ */
    /*gap: 1px; !* فاصله کم بین سلول‌ها برای نمایش خطوط کم‌رنگ *!*/
    gap: 0; /* تنظیم فاصله بین خانه‌های جدول به 0 */
    flex-grow: 1;
}

.product-cell {
    flex: 1 1 18%; /* کاهش عرض برای هر سلول */
    max-width: 18%; /* برای اطمینان از اندازه ثابت */
    box-sizing: border-box; /* اضافه کردن box-sizing برای محاسبه صحیح عرض */
    text-align: right; /* راست‌چین کردن متن‌ها */
    border: 1px solid #e0e0e0; /* خطوط کم‌رنگ سلول‌ها */
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s, border-color 0.3s; /* اضافه کردن انیمیشن برای افکت */
    position: relative; /* برای قرار دادن قیمت در سمت چپ پایین */
}

.product-cell:hover {
    background-color: #f0f0f0; /* تغییر رنگ پس‌زمینه هنگام حرکت ماوس */
    border-color: #1890ff; /* تغییر رنگ مرز هنگام حرکت ماوس */
}

.product-image {
    width: 100%;
    height: 300px; /* بازگرداندن ارتفاع تصاویر به مقدار قبلی */
    object-fit: cover; /* برای حفظ تناسب تصویر و پر کردن فضای موجود */
}

.product-info {
    margin-top: 10px;
    text-align: right; /* راست‌چین کردن اطلاعات محصول */
    font-family: 'Vazir', sans-serif; /* استفاده از فونت Vazir */
}

.product-info h3 {
    font-size: 12px; /* تنظیم سایز ۱۲ برای نام محصول */
}

.product-info .description {
    font-size: 10px; /* تنظیم سایز ۱۰ برای توضیحات محصول */
}

.product-rating {
    display: flex;
    align-items: center;
    font-size: 12px; /* تنظیم سایز ۱۲ برای ریتینگ */
    margin-top: 5px; /* تنظیم فاصله کمتر برای ریتینگ */
}

.product-rating i {
    color: #ffd700; /* رنگ طلایی برای ستاره */
    margin-right: 5px;
}

.product-price {
    font-size: 12px; /* تنظیم سایز ۱۲ برای قیمت */
    font-weight: bold; /* بولد کردن قیمت */
    position: absolute; /* قرار دادن قیمت در سمت چپ پایین */
    bottom: 10px;
    left: 10px;
    direction: rtl; /* تنظیم جهت متن به راست‌چین */
}

.filter-container {
    width: 200px; /* عرض فضای فیلتر */
    margin-left: 20px; /* فاصله از جدول محصولات */
    border: 1px solid #e0e0e0; /* خطوط کم‌رنگ فیلتر */
    padding: 10px;
}

/* تنظیمات رسانه‌ای برای صفحه نمایش‌های مختلف */

/* برای تبلت‌ها */
@media (max-width: 1024px) {
    .product-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; /* تکمیل از سمت راست در تبلت‌ها */
        grid-template-columns: repeat(3, 1fr); /* کاهش تعداد ستون‌ها به ۳ در تبلت‌ها */
    }
}

/* برای موبایل */
@media (max-width: 768px) {
    .product-container {
        flex-direction: column;
    }

    .product-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; /* تکمیل از سمت چپ در موبایل */
    }

    .product-cell {
        flex: 1 1 100%; /* عرض کامل برای هر سلول در موبایل */
        max-width: 100%; /* عرض کامل برای هر سلول در موبایل */
        display: flex;
        flex-direction: row-reverse; /* تصویر به سمت راست و متن به سمت چپ */
        align-items: center;
        padding: 10px;
        width: 100%;
        box-sizing: border-box; /* اضافه کردن box-sizing برای محاسبه صحیح عرض */
        border-bottom: 1px solid #e0e0e0; /* اضافه کردن خط زیرین برای جداکردن محصولات */
        text-align: right; /* راست‌چین کردن متن‌ها در موبایل */
        height: 170px;
    }

    .product-image {
        width: 150px; /* افزایش عرض تصاویر */
        height: auto; /* تنظیم ارتفاع برای حفظ تناسب تصویر */
        margin-left: 10px; /* فاصله بین تصویر و متن */
    }

    .product-info {
        flex-grow: 1; /* افزایش فضای متن */
        text-align: right; /* راست‌چین کردن اطلاعات محصول */
        margin-top: 0;
    }

    .product-rating {
        margin-top: 10px; /* تنظیم فاصله بیشتر برای ریتینگ */
    }

    .product-price {
        margin-top: 10px; /* افزودن فاصله بین امتیاز و قیمت */
    }

    .filter-container {
        width: 100%; /* عرض فضای فیلتر در موبایل */
        margin: 10px 0; /* فاصله بالا و پایین از جدول محصولات */
    }
}
