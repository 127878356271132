.shop-container {
    padding-top: 120px;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 0; /* فاصله بین کارت‌ها حذف شده */
    justify-items: center; /* برای تنظیم مکان کارت‌ها در مرکز خانه‌ها */
}

.product-card {
    width: 100%;
    text-align: right;
    border: 1px solid #e0e0e0;
    border-radius: 10px; /* گوشه‌ها به کارت اضافه شده */
    overflow: hidden; /* برای جلوگیری از ریختن محتوا از کارت */
}

.product-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.product-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.category-tabs {
    margin: 10px 0;
    font-family: Digikala, sans-serif;
}

/* برای موبایل */
@media (max-width: 768px) {
    .product-list {
        grid-template-columns: 1fr; /* تغییر به یک ستون در موبایل */
        gap: 0; /* فاصله بین کارت‌ها حذف شده */
    }

    .product-card {
        display: flex;
        flex-direction: row-reverse; /* عکس سمت راست و توضیحات سمت چپ */
        align-items: center;
        padding: 10px;
        width: 100%; /* عرض کامل برای هر کارت در موبایل */
    }

    .product-card img {
        width: 200px; /* افزایش عرض تصاویر */
        height: auto; /* تنظیم ارتفاع به طور خودکار */
        margin-left: 10px; /* فاصله بین عکس و توضیحات */
    }

    .category-tabs {
        font-size: 12px;
    }
}
