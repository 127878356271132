/* Font face declarations for various fonts */
@font-face {
    font-family: 'BrandonGrotesque';
    src: url("/src/contexts/font/BrandonGrotesque-Thin.ttf") format('truetype');
}

@font-face {
    font-family: 'shekasteh';
    src: url("/src/contexts/font/Shekasteh.ttf") format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'IranNastaliq';
    src: url("/src/contexts/font/IranNastaliq.ttf") format('truetype');
}

@font-face {
    font-family: 'BNazanin';
    src: url("/src/contexts/font/BNazanin.ttf") format('truetype');
}

@font-face {
    font-family: 'hamed';
    src: url("/src/contexts/font/W_hamed.ttf") format('truetype');
}

@font-face {
    font-family: 'lalezar';
    src: url("/src/contexts/font/Lalezar-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'vazir';
    src: url("/src/contexts/font/Vazir.ttf") format('truetype');
}

@font-face {
    font-family: 'vazirlight';
    src: url("/src/contexts/font/Vazir-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'sogand';
    src: url("/src/contexts/font/SOGAND.ttf") format('truetype');
}

@font-face {
    font-family: 'titr';
    src: url("/src/contexts/font/B Titr Bold_0.ttf") format('truetype');
}

@font-face {
    font-family: 'nazaninbold';
    src: url("/src/contexts/font/BNazaninBold_0.ttf") format('truetype');
}

@font-face {
    font-family: 'parastoobold';
    src: url("/src/contexts/font/Parastoo-Print-Bold-FD.ttf") format('truetype');
}

@font-face {
    font-family: 'dimaShekasteh';
    src: url("/src/contexts/font/DimaShekasteh.ttf") format('truetype');
}

@font-face {
    font-family: 'IranianSans';
    src: url("/src/contexts/font/Iranian Sans.ttf") format('truetype');
}

@font-face {
    font-family: 'Digikala';
    src: url("/src/contexts/font/Digikala -_font.ttf") format('truetype');
}

.footer {
    padding: 3em 0;
    margin-top: 4em;
    text-align: center;
}

.footer__link {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--clr-fg);
}

@media (max-width: 600px) {
    .footer {
        padding: 2em;
        margin-top: 3em;
    }
}

.copyr {
    font-family: BrandonGrotesque;
    letter-spacing: 5px;
    font-weight: bolder;
    transition: border-color .15s ease-out, color .15s ease-out;
}

.underline span {
    border-bottom: 1px solid rgba(68, 68, 68, 0.51);
    font-size: 11px;
}

.copyr p {
    margin-bottom: 10px;
}

.copyr p span:hover {
    color: rgba(144, 160, 217, 0.5);
    border-bottom: 1px solid rgba(144, 160, 217, 0.5);
}

.copyrb {
    font-family: BrandonGrotesque;
    letter-spacing: 5px;
    font-weight: bolder;
    transition: border-color .15s ease-out, color .15s ease-out;
    margin-top: 20px;
    font-size: 11px;
}

/*.shekasteh{*/
/*  font-family: shekasteh;*/
/*  font-size: 59px;*/
/*  font-weight: normal;*/
/*}*/

.shekasteh {
    font-family: shekasteh;
    /*font-size: 59px;*/
    font-size: calc(1vw + 30px);
    font-weight: normal;
}

.nastaliqJava {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 20px);
    font-weight: normal;
}


.nastaliqJavaT {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 20px);
    font-weight: normal;
}

.shekastehlastname {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 35px);
    font-weight: normal;
}


.shekastehnamepersion {
    font-family: shekasteh;
    /*font-size: 59px;*/
    font-size: calc(1vw + 30px);
    font-weight: normal;
}

.shekastehJavaT {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 11px);
    font-weight: normal;
}

.dimaShekasteh {
    font-family: dimaShekasteh;
    font-size: 39px;
    color: var(--clr-fg);
    display: contents;
    font-weight: normal;
}

.nastaliq {
    font-family: IranNastaliq;
    font-size: 30px;
    color: var(--clr-fg-alt);
    font-weight: normal;
}

.bNazanin {
    font-family: bNazanin;
    font-size: 26px;
    color: var(--clr-fg-alt);
}

.bNazanint {
    font-family: bNazanin;
    font-size: 18px;
    text-align: right;
}

.bNazanintMessTitle {
    font-family: bNazanin;
    font-size: 16px;
    text-align: right;
}

.bNazanintMessTitleM {
    font-family: bNazanin;
    font-size: 12px;
    text-align: right;
    /*font-weight: bold;*/
}

.bNazanintMess {
    font-family: bNazanin;
    font-size: 14px;
    text-align: right;
}

.bNazanintN {
    font-family: bNazanin;
    font-size: 18px;
    text-align: center;
    color: var(--clr-fg-alt);
}

.bNazanintP {
    font-family: bNazanin;
    font-size: 18px;
    text-align: center;
}

.bNazanintPb {
    font-family: bNazanin;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.bNazanint2 {
    font-family: bNazanin;
    font-size: 18px;
    text-align: right;
    padding: 20px;
}

.bNazaninF {
    font-family: bNazanin;
    font-size: 15px;
    text-align: justify;
    direction: rtl;
}

.bNazanintd {
    font-family: vazir;
    font-size: 12px;
    text-align: left;
}

.training {
    font-family: vazir;
    text-align: center;
}

.bNazanintdr {
    font-family: vazir;
    /*font-size: 12px;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrQ {
    font-family: vazir;
    font-size: 12px;
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrT {
    font-family: vazir;
    font-size: 13px;
    /*font-weight: bold;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrD {
    font-family: vazir;
    font-size: 12px;
    /*font-weight: bold;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrDB {
    font-family: vazir;
    font-size: 12px;
    font-weight: bold;
    color: var(--clr-fg-alt);
}

.bNazanintdrDBlog {
    font-family: vazir;
    font-size: 12px;
    font-weight: bold;
    color: var(--clr-primary);
}

.bNazanintdrN {
    font-family: vazir;
    font-size: 8px;
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrb {
    font-family: vazir;
    /*font-size: 12px;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
    font-weight: bold;

}

.hamed {
    font-family: hamed;
    font-size: 26px;
    color: var(--clr-fg-alt);
}

.lalezar {
    font-family: lalezar;
    font-size: 18px;
    color: var(--clr-fg-alt);
}

.lalezarI {
    font-family: lalezar;
    font-size: 10px;
    color: var(--clr-fg-alt);
}

.lalezarShop {
    font-family: lalezar;
    font-size: 14px;
    color: var(--clr-fg-alt);
    margin-bottom: 20px;
}

.lalezarLink {
    font-family: lalezar;
    font-size: 15px;
    color: var(--clr-fg-alt);
}

.lalezarLinkB {
    font-family: lalezar;
    font-size: 15px;
    color: #CDCDFFFF;
}

.vazir {
    font-family: vazir, sans-serif;
    font-size: 18px;
    color: var(--clr-fg-alt);
}

.vazirab {
    font-family: vazir, sans-serif;
    font-size: 16px;
    color: var(--clr-fg-alt);
    text-align: justify;
    direction: rtl;
}

.vazirablog {
    font-family: vazir, sans-serif;
    font-size: 12px;
    color: var(--clr-fg-alt);
    text-align: justify;
    direction: rtl;
}

.vazirlight {
    font-family: vazirlight;
    font-size: 18px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.vazirlightd {
    font-family: vazirlight;
    font-size: 12px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.sogand {
    font-family: sogand;
    font-size: 18px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.sogandUser {
    font-family: sogand;
    font-size: 24px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.parastoobold {
    font-family: parastoobold;
}

.bTitr {
    font-family: titr, sans-serif;
    /*font-size: 18px;*/
    color: var(--clr-fg-alt);
    /*font-weight: bold;*/
}

.bTitrShop {
    font-family: titr;
    font-size: 18px;
    color: var(--clr-fg-alt);
    /*font-weight: bold;*/
}

.bTitrShopS {
    font-family: titr;
    font-size: 18px;
    color: var(--clr-primary);
    /*font-weight: bold;*/
}

.bTitrImm {
    font-family: titr;
    font-size: 24px;
    color: var(--clr-primary);
    /*font-weight: bold;*/
}

.bTitrBlog {
    font-family: titr;
    font-size: 21px;
    color: var(--clr-primary);
    /*font-weight: bold;*/
}