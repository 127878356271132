.app {
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    color: var(--clr-fg);
    background-color: var(--clr-bg);
}

.light {
    --clr-bg: #fcfcfc;
    --clr-bg-alt: #fff;
    --clr-fg: #555;
    --clr-fg-alt: #444;
    --clr-primary: #2978b5;
    --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
    --clr-bg: #23283e;
    --clr-bg-alt: #2a2f4c;
    --clr-fg: #bdbddd;
    --clr-fg-alt: #cdcdff;
    --clr-primary: #90a0d9;
    --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
}

.section {
    margin-top: 5em;
}

.section__title {
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
    padding-top: 90px;
}

.center {
    display: flex;
    align-items: center;
}

.link {
    color: var(--clr-primary);
    padding: 0 0 0.3em 0;
    position: relative;
}

.link:hover {
    color: var(--clr-primary);
}

.link::before {
    content: '';
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: 0;
    background-color: var(--clr-primary);
    transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
    width: 100%;
}

.link--nav {
    color: var(--clr-fg);
    text-transform: lowercase;
    font-weight: 500;
}

.link--icon {
    color: var(--clr-fg);
}

.btn {
    display: block;
    cursor: pointer;
    padding: 0.8em 1.4em;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: lowercase;
    transition: transform 0.2s ease-in-out;
}

.btn--outline {
    color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
    color: var(--clr-bg);
}

.btn--outline:before {
    content: '';
    position: absolute;
    background-color: var(--clr-primary);
    right: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: -1;
    transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
    right: 0;
}

.btn--plain {
    text-transform: initial;
    background-color: var(--clr-bg-alt);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    border: 0;
    width: 95px;
    height: 83px;
}

.btn--plain:hover {
    transform: translateY(-4px);
}

.btn--icon {
    padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
    color: var(--clr-primary);
}

.btn--icon:active {
    transform: translateY(-5px);
}

@media (max-width: 600px) {
    .section {
        margin-top: 4em;
    }
}


.scrollable-container {
    height: 100px;
    overflow-y: scroll;
}
.background {
    padding-top: 60px;
    height: 300px;
    background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
}


@keyframes wave {
    0%, 100% {
        color: red;
    }
    50% {
        color: blue;
    }
}

.waveText {
    display: inline-block;
    background: linear-gradient(90deg, red, orange, yellow, green, blue);
    -webkit-background-clip: text;
    color: transparent;
    animation: wave 3s infinite;
    background-size: 200% 100%;
    background-position: 0% 0%;
    animation: wave 5s linear infinite;
}

@keyframes wave {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}

