
.submit-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    opacity: 0.8;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    position: relative;
}

.submit-button::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: white; /* رنگ حاشیه */
    border-top-right-radius: 4px; /* تنظیم شعاع حاشیه برای گوشه بالا-راست */
    border: 2px solid #007bff; /* تنظیم حاشیه */
    z-index: -1; /* قرار دادن حاشیه در زیر دکمه */
}

.submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    opacity: 1;
}

.submit-button:active {
    background-color: #004494;
    transform: translateY(0);
    opacity: 0.9;
}

.rtl-marquee {
    direction: rtl;
    font-family: lalezarI, serif;
}

