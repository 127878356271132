.contact {
    flex-direction: column;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.map-column {
    order: initial;
}

.contact-info {
    order: initial;
    text-align: right;
    font-family: 'Vazir', sans-serif;
    direction: rtl;
}

.contact-form {
    order: initial;
    text-align: right;
    font-family: 'Vazir', sans-serif;
    direction: rtl;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/*.submit-button {*/
/*    padding: 10px;*/
/*    background-color: #007bff;*/
/*    color: white;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*    width: 100%;*/
/*    opacity: 0.5;*/
/*    display: block;*/
/*    margin: 0 auto;*/
/*}*/

/*.submit-button:hover {*/
/*    background-color: #0056b3;*/
/*}*/

/*.submit-button:disabled {*/
/*    background-color: #ccc;*/
/*    cursor: not-allowed;*/
/*    opacity: 0.5;*/
/*}*/

.submit-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    opacity: 0.8;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    position: relative;
}

.submit-button::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: white; /* رنگ حاشیه */
    border-top-right-radius: 4px; /* تنظیم شعاع حاشیه برای گوشه بالا-راست */
    border: 2px solid #007bff; /* تنظیم حاشیه */
    z-index: -1; /* قرار دادن حاشیه در زیر دکمه */
}

.submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    opacity: 1;
}

.submit-button:active {
    background-color: #004494;
    transform: translateY(0);
    opacity: 0.9;
}

.submit-button:enabled {
    opacity: 1;
}

.vazirabC {
    font-family: 'Vazir', sans-serif;
    direction: rtl;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    width: fit-content;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
    }

    .map-column {
        order: 2;
        margin-top: 5em;
    }

    .contact-info {
        order: 1;
        text-align: right;
        font-family: 'Vazir', sans-serif;
        direction: rtl;
    }

    .contact-form {
        order: 3;
        margin-top: 2em;
        text-align: right;
        font-family: 'Vazir', sans-serif;
        direction: rtl;
    }

    .section1 {
        margin-top: 5em;
    }

    .section__title1 {
        text-align: center;
        text-transform: uppercase;
    }
}

@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .map-column, .contact-info, .contact-form {
        order: initial;
        text-align: right;
        font-family: 'Vazir', sans-serif;
        direction: rtl;
    }

    .section1 {
        margin-top: 5em;
    }

    .section__title1 {
        text-align: center;
        margin-bottom: 1em;
        text-transform: uppercase;
        padding-top: 90px;
    }
}

/* Contact.css */
.links-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px; /* فاصله بین لینک‌ها */
    margin-top: 3.2em;
}

.links-container a {
    flex: 1;
    min-width: 200px; /* حداقل عرض لینک‌ها */
    text-align: center;
}

