.coaching-path {
    padding: 5px;
    text-align: center;
}

.progress-bar {
    width: 5px;
    margin: 0 auto;
    background-color: #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.step {
    width: 200px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.step.left {
    align-self: flex-start;
    transform: translateX(-150%);
}

.step.right {
    align-self: flex-end;
    transform: translateX(150%);
}

.step.final {
    width: auto;
    max-width: none;
    transform: translateX(0);
    opacity: 1;
}

.step.visible {
    opacity: 1;
    transform: translateX(0) translateY(0);
}

.step h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.step p {
    font-size: 14px;
    margin: 0;
}

.signup-link {
    font-size: 12px;
    font-family: 'lalezar';
}

.scene-icon-e {
    display: flex;
    justify-content: center;
    align-items: center; /* اضافه کردن این خط برای قرارگیری عمودی */
    /*margin: 40px 0;*/
    /*height: 40px; !* ارتفاع دلخواه *!*/
}