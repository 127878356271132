/* Blog.css */

.header-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.sidebar {
    width: 20%;
    padding: 20px;
    background-color: #f4f4f4;
}

.content {
    width: 80%;
    padding: 20px;
    background-color: #fff;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #333;
}

.sidebar ul li a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
    }

    .content {
        width: 100%;
    }
}

/* کلاس جدید برای راست‌چین کردن */
.right-align {
    text-align: left;
}

/*!* کلاس جدید برای تگ li *!*/
/*.justify-li {*/
/*    text-align: justify;*/
/*    !*font-family: bNazanintdr;*!*/
/*}*/

