.skills__list {
  max-width: 950px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

.image {
  margin:0 auto;
  max-height: 50px;
  width: auto;
  display: inline-block !important;
  height: 50px !important;
}