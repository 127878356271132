/* استایل‌های اصلی */
body {
    background-color: #f8f8f8; /* رنگ پس‌زمینه صفحه */
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white; /* رنگ پس‌زمینه هدر */
    border-bottom: 1px solid #e0e0e0; /* یک مرز ظریف برای تفاوت */
}

.logo {
    width: 130px; /* بزرگتر شدن لوگو */
}

.title-section {
    text-align: right;
}

.title {
    font-family: 'Lalezar', sans-serif;
    font-size: 2em;
    margin: 0 0 10px 0; /* اضافه کردن فاصله بین عنوان‌ها */
}

.subtitle {
    font-family: 'Lalezar', sans-serif;
    font-size: 1.5em;
    margin: 0 0 10px 0; /* اضافه کردن فاصله بین عنوان‌ها */
}

.description {
    font-family: 'Vazir', sans-serif;
    font-size: 1em;
    margin: 0 0 10px 0; /* اضافه کردن فاصله بین عنوان‌ها */
}

.table-section {
    padding: 20px;
    position: relative; /* برای موقعیت‌دهی نوار جستجو */
}

.search-bar {
    margin-bottom: 20px;
    width: 200px; /* کوچکتر کردن نوار جستجو */
    position: absolute;
    right: 0; /* قرار گرفتن در گوشه سمت راست */
    top: 10px; /* اضافه کردن فاصله از بالای بخش جدول */
    text-align: right; /* راست چین کردن متن داخل نوار جستجو */
}

.spacing {
    height: 40px; /* تنظیم ارتفاع برای ایجاد فاصله مناسب */
}

.center-align-table .ant-table-thead > tr > th {
    text-align: center; /* وسط چین کردن تایتل ستون‌ها */
    font-family: 'bTitrShopS', sans-serif; /* اعمال فونت Lalezar به تایتل ستون‌ها */
}

.center-align-table .ant-table-thead > tr > th,
.center-align-table .ant-table-tbody > tr > td {
    text-align: right; /* راست چین کردن متن داخل سلول‌های جدول */
    font-family: 'Vazir', sans-serif; /* اعمال فونت bNazanintN به داده‌ها */
}

.course-name-column {
    white-space: nowrap; /* جلوگیری از شکستن خط */
    overflow: hidden;
    text-overflow: ellipsis;
}

/* استایل‌های ریسپانسیو */
@media (max-width: 768px) {
    .header-section {
        flex-direction: column;
        text-align: center;
    }

    .logo {
        margin-bottom: 20px;
        width: 120px; /* تنظیم اندازه برای موبایل */
    }

    .title {
        font-size: 1.5em;
    }

    .subtitle {
        font-size: 1.2em;
    }

    .description {
        font-size: 0.9em;
    }

    .search-bar {
        width: 100%; /* تنظیم نوار جستجو برای موبایل */
        position: relative;
        right: auto;
        top: auto;
        margin-bottom: 10px;
    }

    .spacing {
        height: 20px; /* تنظیم ارتفاع برای ایجاد فاصله مناسب در موبایل */
    }

    /* حذف ستون‌های غیر ضروری در حالت ریسپانسیو */
    .ant-table-cell.hide-column {
        display: none;
    }
}

.footer-links {
    text-align: center;
    padding: 20px 0;
    margin-top: 20px; /* فاصله بین ایکون ها و ستون های جدول */
}

.footer-links .logo-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 20px; /* فاصله بین <td> ها */
}

.footer-links a {
    display: block;
    text-decoration: none;
    color: black;
    text-align: center;
}

.footer-links a:hover {
    color: blue;
}

.footer-links .logo-box {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5px;
    border: 1px solid #ccc;
}

.footer-links .logo-image {
    max-width: 50%;
    max-height: 50%;
}

.small-text {
    font-size: 0.8em;
}