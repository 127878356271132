/* HeaderShop.css */
.notification-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
    padding: 7px 10px;
    background-color: #fff4e5;
    border-bottom: 1px solid #ffd591;
    font-size: 10px;
    text-align: center;
}

.notification-bar a {
    color: #000;
    text-decoration: none;
    font-family: 'Vazir', sans-serif;
}

.notification-bar a:hover {
    text-decoration: underline;
}

.app-header {
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 64px;
    direction: rtl;
    position: fixed;
    top: 25px;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

.logo-search-container {
    display: flex;
    align-items: center;
    flex: 1;
}

.logo-store {
    margin-right: 0px;
}

.search-section {
    display: flex;
    align-items: center;
    flex: 1;
}

.search-input-custom {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    font-family: 'Vazir', sans-serif !important;
}

.search-section .ant-input::placeholder {
    font-family: 'Vazir', sans-serif !important;
}

.actions-section {
    display: flex;
    align-items: center;
    margin-right: auto;
    font-family: 'Vazir', sans-serif !important;
}

.actions-section .action-btn,
.actions-section .cart-icon,
.actions-section .ant-btn-link {
    color: #000000 !important;
}

.action-btn {
    display: flex;
    align-items: center;
    gap: 5px;
}

.action-btn .anticon {
    margin-right: 0;
}

.content {
    padding-top: 104px;
}

.store-name {
    font-family: 'Lalezar', sans-serif;
    font-size: 20px;
    color: #ff8c00;
    margin-left: 5px;
}

@media (max-width: 768px) {
    .app-header .logo-store {
        display: none;
    }

    .app-header {
        padding: 0 10px;
    }

    .logo {
        margin-right: 5px;
    }

    .search-section {
        flex: 1 1 100%;
        order: 2;
    }

    .actions-section {
        flex: 1 1 100%;
        justify-content: flex-end;
        order: 3;
    }

    .bottom-icons {
        display: flex;
        justify-content: space-around; /* تنظیم فاصله مساوی بین آیکون‌ها */
        align-items: center;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }

    .icon-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .actions-section {
        display: none;
    }

    .login-btn {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .login-btn .anticon {
        margin-right: 0;
    }

    .login-btn::after {
        content: none;
    }

    .mobile-categories {
        position: fixed;
        top: 89px; /* فاصله از بالای صفحه تا هدر */
        right: 0;
        width: 90px; /* عرض کوچک‌تر برای دسته‌بندی‌ها */
        height: calc(100% - 138px); /* ارتفاع با در نظر گرفتن هدر و فوتر */
        background-color: #efeff0;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        z-index: 900; /* تغییر z-index */
        padding-top: 20px; /* فاصله داخلی بالای صفحه */
        overflow-y: auto; /* فعال‌کردن اسکرول در صورت نیاز */
    }

    .category-item {
        width: 100%;
        text-align: right;
        padding: 10px 15px; /* فاصله داخلی دسته‌بندی‌ها */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .category-icon {
        font-size: 24px; /* اندازه آیکون */
        color: #333; /* رنگ آیکون */
        margin-bottom: 5px; /* فاصله بین آیکون و نام دسته‌بندی */
    }

    .category-name {
        font-size: 14px; /* سایز فونت دلخواه */
        color: #333; /* رنگ متن دسته‌بندی */
    }
}

.search-results-list {
    position: absolute;
    top: 40px; /* فاصله از بالای کادر جستجو */
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 300px; /* تنظیم حداکثر ارتفاع */
    overflow-y: auto; /* فعال‌کردن اسکرول در صورت نیاز */
    direction: rtl;
    text-align: right;
    /* راست‌چین کردن متن */
    font-family: vazir, sans-serif;
}

.search-results-list .ant-list-item {
    padding: 10px;
    cursor: pointer;
}

.search-results-list .ant-list-item:hover {
    background-color: #f5f5f5;
}

.icon-label {
    font-family: 'IranianSans', sans-serif; /* استفاده از فونت IranianSans */
    font-size: 10px;
    margin-top: 4px; /* فاصله بین آیکون و متن */
    color: rgb(175, 176, 181);
}

.icon-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* HeaderShop.css */
.category-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px; /* ارتفاع دلخواه برای آیتم‌های لیست */
    font-family: vazir, sans-serif;
    font-size: 16px;
}

.category-icon {
    font-size: 15px; /* اندازه آیکون */
    color: #414750; /* رنگ آیکون */
    margin-bottom: 3px; /* فاصله بین آیکون و نام دسته‌بندی */
}

.category-name {
    color: #414750; /* رنگ متن دسته‌بندی */
    text-align: center; /* متن دسته‌بندی در مرکز */
    font-size: 10px;
}
