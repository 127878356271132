/*div {*/
/*    text-align: center;*/
/*}*/
.ant-col.ant-form-item-label {
    text-align: right;
}

.ant-input.ant-input-status-success.bNazanintdr {
    text-align: left;
}

.ant-input ant-input-status-error bNazanintdr {
    text-align: left;
}