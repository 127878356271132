/* Reset and global styles for consistency */
.header {
    height: 5em;
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center; /* تراز عمودی همه عناصر */
    justify-content: space-between; /* تقسیم هدر به سه بخش */
    z-index: 1000000000;
    position: sticky;
    top: 0;
}

@media (max-width: 600px) {
    .header {
        height: 6em;
    }
}

.header-left,
.header-center,
.header-right {
    display: flex;
    align-items: center;
}

.header-center {
    justify-content: center;
    flex: 1; /* اشغال فضای باقی‌مانده */
}

.space {
    display: inline-block;
    width: 10px; /* تنظیم فاصله بین آیکون‌ها */
}

.link .anticon {
    font-size: 24px; /* تنظیم اندازه آیکون‌ها */
    transition: color 0.3s ease;
}

.link:hover .anticon {
    color: #1890ff; /* تغییر رنگ در هاور */
}

.training-path-icon {
    animation: spin 2s linear infinite;
    width: 50px; /* تنظیم عرض آیکون متحرک */
    height: 50px; /* تنظیم ارتفاع آیکون متحرک */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.test-icon {
    width: 50px; /* تنظیم عرض آیکن */
    height: 50px; /* تنظیم ارتفاع آیکن */
}

.scene-icon {
    width: 140px; /* تنظیم عرض آیکن */
    height: 140px; /* تنظیم ارتفاع آیکن */
    padding: 0;
}

.dev-icon {
    width: 80px; /* تنظیم عرض آیکن */
    height: 60px; /* تنظیم ارتفاع آیکن */
}

.nestedIcon-icon {
    width: 150px; /* تنظیم عرض آیکن */
    height: 150px; /* تنظیم ارتفاع آیکن */
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.animated-icon {
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}