@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

.about__role {
    font-family: 'Raleway', sans-serif;
    /*font-size: 12px;*/
}

/*@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');*/

/*.about__role {*/
/*  font-family: 'Great Vibes', cursive;*/
/*  !*font-size: 14px;*!*/
/*}*/
/*@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');*/

/*.about__role {*/
/*  font-family: 'Bad Script', cursive;*/
/*  font-size: 12px;*/
/*}*/





/*@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');*/

/*.about__role {*/
/*  font-family: 'Lobster', sans-serif;*/
/*  font-size: 12px;*/
/*}*/


/*@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');*/
/**/
/*.about__role {*/
/*  font-family: 'Lobster', sans-serif;*/
/*  font-size: 10px;*/
/*}*/



.about {
    flex-direction: column;
    margin-top: 3em;
}

.about__name {
    color: var(--clr-primary);
}

.about__name__title {
    /*display: block ruby;*/
    display: flow-root;
    color: var(--clr-fg);
}

.phone__no {
    color: var(--clr-primary);
    font-weight: bold;
}

.about__role {
    margin-top: 1.2em;
    font-size: 20px;
}

.about__desc {
    font-size: 0.8rem;
    /*max-width: 616px;*/
    max-width: 616px;
    text-align: justify;
}

.about__desc,
.about__contact {
    margin-top: 1.1em;
}

.about .link--icon {
    margin-right: 0.8em;
}

.about .btn--outline {
    margin-right: 1em;
}

/*@media (max-width: 600px) {*/
/*  .app .about {*/
/*    align-items: flex-start;*/
/*    margin-top: 2em;*/
/*  }*/
/*}*/

@media (max-width: 600px) {
    .app .about {
        /*align-items: flex-start;*/
        align-items: center;
        margin-top: 2em;
    }
}

.wrapper {
    height: 5vh;
    /*This part is important for centering*/
    display: grid;
    place-items: center;
}

.typing-demo {
    width: 17ch;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-size: 2em;
}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

.typewriter-cursor {
    font-family: 'Arial, sans-serif';
    direction: rtl;
    text-align: right;
    white-space: nowrap;
}
