/*.custom-table {*/
/*    background-color: inherit;*/
/*}*/

/*.custom-thead th {*/
/*    font-size: 18px;*/
/*    font-weight: bold;*/
/*    text-align: center;*/
/*    padding: 10px;*/
/*    background-color: #f0f0f0;*/
/*}*/

.des {
    font-size: 25px;
    text-align: right;
    font-family: IranNastaliq;
}

.centered-table .ant-table-cell {
    text-align: center;
 }