@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
}

.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #90a0d9;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
  rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
  padding-top: 90px;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: '';
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: '';
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
  width: 95px;
  height: 83px;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}


.scrollable-container {
  height: 100px;
  overflow-y: scroll;
}
.background {
  padding-top: 60px;
  height: 300px;
  background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
}
/* Reset and global styles for consistency */
.header {
    height: 5em;
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center; /* تراز عمودی همه عناصر */
    justify-content: space-between; /* تقسیم هدر به سه بخش */
    z-index: 1000000000;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

@media (max-width: 600px) {
    .header {
        height: 6em;
    }
}

.header-left,
.header-center,
.header-right {
    display: flex;
    align-items: center;
}

.header-center {
    justify-content: center;
    flex: 1 1; /* اشغال فضای باقی‌مانده */
}

.space {
    display: inline-block;
    width: 10px; /* تنظیم فاصله بین آیکون‌ها */
}

.link .anticon {
    font-size: 24px; /* تنظیم اندازه آیکون‌ها */
    transition: color 0.3s ease;
}

.link:hover .anticon {
    color: #1890ff; /* تغییر رنگ در هاور */
}

.training-path-icon {
    animation: spin 2s linear infinite;
    width: 50px; /* تنظیم عرض آیکون متحرک */
    height: 50px; /* تنظیم ارتفاع آیکون متحرک */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.test-icon {
    width: 50px; /* تنظیم عرض آیکن */
    height: 50px; /* تنظیم ارتفاع آیکن */
}

.scene-icon {
    width: 140px; /* تنظیم عرض آیکن */
    height: 140px; /* تنظیم ارتفاع آیکن */
    padding: 0;
}

.dev-icon {
    width: 80px; /* تنظیم عرض آیکن */
    height: 60px; /* تنظیم ارتفاع آیکن */
}

.nestedIcon-icon {
    width: 150px; /* تنظیم عرض آیکن */
    height: 150px; /* تنظیم ارتفاع آیکن */
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.animated-icon {
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
.nav__list {
  margin-right: 1.5em;
  margin-bottom: 0em;
  display: flex;
}
.nav__list-item {
  margin-left: 1.5em;
}

.app .nav__hamburger {
  display: none;
}

.nav__theme {
  margin-top: 0.4em;
}

@media (max-width: 600px) {
  .nav__list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .nav__list-item {
    margin: 0.5em 0;
  }

  .app .nav__hamburger {
    display: flex;
    z-index: 2;
    margin-left: 0.8em;
  }
}

.about__role {
    font-family: 'Raleway', sans-serif;
    /*font-size: 12px;*/
}

/*@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');*/

/*.about__role {*/
/*  font-family: 'Great Vibes', cursive;*/
/*  !*font-size: 14px;*!*/
/*}*/
/*@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');*/

/*.about__role {*/
/*  font-family: 'Bad Script', cursive;*/
/*  font-size: 12px;*/
/*}*/





/*@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');*/

/*.about__role {*/
/*  font-family: 'Lobster', sans-serif;*/
/*  font-size: 12px;*/
/*}*/


/*@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');*/
/**/
/*.about__role {*/
/*  font-family: 'Lobster', sans-serif;*/
/*  font-size: 10px;*/
/*}*/



.about {
    flex-direction: column;
    margin-top: 3em;
}

.about__name {
    color: var(--clr-primary);
}

.about__name__title {
    /*display: block ruby;*/
    display: flow-root;
    color: var(--clr-fg);
}

.phone__no {
    color: var(--clr-primary);
    font-weight: bold;
}

.about__role {
    margin-top: 1.2em;
    font-size: 20px;
}

.about__desc {
    font-size: 0.8rem;
    /*max-width: 616px;*/
    max-width: 616px;
    text-align: justify;
}

.about__desc,
.about__contact {
    margin-top: 1.1em;
}

.about .link--icon {
    margin-right: 0.8em;
}

.about .btn--outline {
    margin-right: 1em;
}

/*@media (max-width: 600px) {*/
/*  .app .about {*/
/*    align-items: flex-start;*/
/*    margin-top: 2em;*/
/*  }*/
/*}*/

@media (max-width: 600px) {
    .app .about {
        /*align-items: flex-start;*/
        align-items: center;
        margin-top: 2em;
    }
}

.wrapper {
    height: 5vh;
    /*This part is important for centering*/
    display: grid;
    place-items: center;
}

.typing-demo {
    width: 17ch;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-size: 2em;
}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

.typewriter-cursor {
    font-family: 'Arial, sans-serif';
    direction: rtl;
    text-align: right;
    white-space: nowrap;
}

.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}

.dnon {
  display: none;
}


.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}

.skills__list {
  max-width: 950px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.5em;
}

.image {
  margin:0 auto;
  max-height: 50px;
  width: auto;
  display: inline-block !important;
  height: 50px !important;
}
.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 4em;
  background-color: transparent;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}

.contact {
    flex-direction: column;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    gap: 20px;
}

.map-column {
    order: initial;
}

.contact-info {
    order: initial;
    text-align: right;
    font-family: 'Vazir', sans-serif;
    direction: rtl;
}

.contact-form {
    order: initial;
    text-align: right;
    font-family: 'Vazir', sans-serif;
    direction: rtl;
}

.contact-form form {
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/*.submit-button {*/
/*    padding: 10px;*/
/*    background-color: #007bff;*/
/*    color: white;*/
/*    border: none;*/
/*    border-radius: 4px;*/
/*    cursor: pointer;*/
/*    width: 100%;*/
/*    opacity: 0.5;*/
/*    display: block;*/
/*    margin: 0 auto;*/
/*}*/

/*.submit-button:hover {*/
/*    background-color: #0056b3;*/
/*}*/

/*.submit-button:disabled {*/
/*    background-color: #ccc;*/
/*    cursor: not-allowed;*/
/*    opacity: 0.5;*/
/*}*/

.submit-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    opacity: 0.8;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    position: relative;
}

.submit-button::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: white; /* رنگ حاشیه */
    border-top-right-radius: 4px; /* تنظیم شعاع حاشیه برای گوشه بالا-راست */
    border: 2px solid #007bff; /* تنظیم حاشیه */
    z-index: -1; /* قرار دادن حاشیه در زیر دکمه */
}

.submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    opacity: 1;
}

.submit-button:active {
    background-color: #004494;
    transform: translateY(0);
    opacity: 0.9;
}

.submit-button:enabled {
    opacity: 1;
}

.vazirabC {
    font-family: 'Vazir', sans-serif;
    direction: rtl;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
    }

    .map-column {
        order: 2;
        margin-top: 5em;
    }

    .contact-info {
        order: 1;
        text-align: right;
        font-family: 'Vazir', sans-serif;
        direction: rtl;
    }

    .contact-form {
        order: 3;
        margin-top: 2em;
        text-align: right;
        font-family: 'Vazir', sans-serif;
        direction: rtl;
    }

    .section1 {
        margin-top: 5em;
    }

    .section__title1 {
        text-align: center;
        text-transform: uppercase;
    }
}

@media (min-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .map-column, .contact-info, .contact-form {
        order: initial;
        text-align: right;
        font-family: 'Vazir', sans-serif;
        direction: rtl;
    }

    .section1 {
        margin-top: 5em;
    }

    .section__title1 {
        text-align: center;
        margin-bottom: 1em;
        text-transform: uppercase;
        padding-top: 90px;
    }
}

/* Contact.css */
.links-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px; /* فاصله بین لینک‌ها */
    margin-top: 3.2em;
}

.links-container a {
    flex: 1 1;
    min-width: 200px; /* حداقل عرض لینک‌ها */
    text-align: center;
}


/* Font face declarations for various fonts */
@font-face {
    font-family: 'BrandonGrotesque';
    src: url(https://www.esmaeilsadeghi.ir/static/media/BrandonGrotesque-Thin.f644e287.ttf) format('truetype');
}

@font-face {
    font-family: 'shekasteh';
    src: url(https://www.esmaeilsadeghi.ir/static/media/Shekasteh.02da30e5.ttf) format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: 'IranNastaliq';
    src: url(https://www.esmaeilsadeghi.ir/static/media/IranNastaliq.32e9d4e9.ttf) format('truetype');
}

@font-face {
    font-family: 'BNazanin';
    src: url(https://www.esmaeilsadeghi.ir/static/media/BNazanin.5e9461d6.ttf) format('truetype');
}

@font-face {
    font-family: 'hamed';
    src: url(https://www.esmaeilsadeghi.ir/static/media/W_hamed.3948d2a9.ttf) format('truetype');
}

@font-face {
    font-family: 'lalezar';
    src: url(https://www.esmaeilsadeghi.ir/static/media/Lalezar-Regular.241ca811.ttf) format('truetype');
}

@font-face {
    font-family: 'vazir';
    src: url(https://www.esmaeilsadeghi.ir/static/media/Vazir.8698ab71.ttf) format('truetype');
}

@font-face {
    font-family: 'vazirlight';
    src: url(https://www.esmaeilsadeghi.ir/static/media/Vazir-Light.7bbe0ee6.ttf) format('truetype');
}

@font-face {
    font-family: 'sogand';
    src: url(https://www.esmaeilsadeghi.ir/static/media/SOGAND.de617fe8.ttf) format('truetype');
}

@font-face {
    font-family: 'titr';
    src: url("https://www.esmaeilsadeghi.ir/static/media/B Titr Bold_0.ddf1cf60.ttf") format('truetype');
}

@font-face {
    font-family: 'nazaninbold';
    src: url(https://www.esmaeilsadeghi.ir/static/media/BNazaninBold_0.234e90c5.ttf) format('truetype');
}

@font-face {
    font-family: 'parastoobold';
    src: url(https://www.esmaeilsadeghi.ir/static/media/Parastoo-Print-Bold-FD.dd53c25c.ttf) format('truetype');
}

@font-face {
    font-family: 'dimaShekasteh';
    src: url(https://www.esmaeilsadeghi.ir/static/media/DimaShekasteh.0e0451a3.ttf) format('truetype');
}

@font-face {
    font-family: 'IranianSans';
    src: url("https://www.esmaeilsadeghi.ir/static/media/Iranian Sans.8c2ed8fb.ttf") format('truetype');
}

@font-face {
    font-family: 'Digikala';
    src: url("https://www.esmaeilsadeghi.ir/static/media/Digikala -_font.7bd94866.ttf") format('truetype');
}

.footer {
    padding: 3em 0;
    margin-top: 4em;
    text-align: center;
}

.footer__link {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--clr-fg);
}

@media (max-width: 600px) {
    .footer {
        padding: 2em;
        margin-top: 3em;
    }
}

.copyr {
    font-family: BrandonGrotesque;
    letter-spacing: 5px;
    font-weight: bolder;
    transition: border-color .15s ease-out, color .15s ease-out;
}

.underline span {
    border-bottom: 1px solid rgba(68, 68, 68, 0.51);
    font-size: 11px;
}

.copyr p {
    margin-bottom: 10px;
}

.copyr p span:hover {
    color: rgba(144, 160, 217, 0.5);
    border-bottom: 1px solid rgba(144, 160, 217, 0.5);
}

.copyrb {
    font-family: BrandonGrotesque;
    letter-spacing: 5px;
    font-weight: bolder;
    transition: border-color .15s ease-out, color .15s ease-out;
    margin-top: 20px;
    font-size: 11px;
}

/*.shekasteh{*/
/*  font-family: shekasteh;*/
/*  font-size: 59px;*/
/*  font-weight: normal;*/
/*}*/

.shekasteh {
    font-family: shekasteh;
    /*font-size: 59px;*/
    font-size: calc(1vw + 30px);
    font-weight: normal;
}

.nastaliqJava {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 20px);
    font-weight: normal;
}


.nastaliqJavaT {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 20px);
    font-weight: normal;
}

.shekastehlastname {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 35px);
    font-weight: normal;
}


.shekastehnamepersion {
    font-family: shekasteh;
    /*font-size: 59px;*/
    font-size: calc(1vw + 30px);
    font-weight: normal;
}

.shekastehJavaT {
    font-family: IranNastaliq;
    /*font-size: 59px;*/
    font-size: calc(1vw + 11px);
    font-weight: normal;
}

.dimaShekasteh {
    font-family: dimaShekasteh;
    font-size: 39px;
    color: var(--clr-fg);
    display: contents;
    font-weight: normal;
}

.nastaliq {
    font-family: IranNastaliq;
    font-size: 30px;
    color: var(--clr-fg-alt);
    font-weight: normal;
}

.bNazanin {
    font-family: bNazanin;
    font-size: 26px;
    color: var(--clr-fg-alt);
}

.bNazanint {
    font-family: bNazanin;
    font-size: 18px;
    text-align: right;
}

.bNazanintMessTitle {
    font-family: bNazanin;
    font-size: 16px;
    text-align: right;
}

.bNazanintMessTitleM {
    font-family: bNazanin;
    font-size: 12px;
    text-align: right;
    /*font-weight: bold;*/
}

.bNazanintMess {
    font-family: bNazanin;
    font-size: 14px;
    text-align: right;
}

.bNazanintN {
    font-family: bNazanin;
    font-size: 18px;
    text-align: center;
    color: var(--clr-fg-alt);
}

.bNazanintP {
    font-family: bNazanin;
    font-size: 18px;
    text-align: center;
}

.bNazanintPb {
    font-family: bNazanin;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
}

.bNazanint2 {
    font-family: bNazanin;
    font-size: 18px;
    text-align: right;
    padding: 20px;
}

.bNazaninF {
    font-family: bNazanin;
    font-size: 15px;
    text-align: justify;
    direction: rtl;
}

.bNazanintd {
    font-family: vazir;
    font-size: 12px;
    text-align: left;
}

.training {
    font-family: vazir;
    text-align: center;
}

.bNazanintdr {
    font-family: vazir;
    /*font-size: 12px;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrQ {
    font-family: vazir;
    font-size: 12px;
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrT {
    font-family: vazir;
    font-size: 13px;
    /*font-weight: bold;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrD {
    font-family: vazir;
    font-size: 12px;
    /*font-weight: bold;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrDB {
    font-family: vazir;
    font-size: 12px;
    font-weight: bold;
    color: var(--clr-fg-alt);
}

.bNazanintdrDBlog {
    font-family: vazir;
    font-size: 12px;
    font-weight: bold;
    color: var(--clr-primary);
}

.bNazanintdrN {
    font-family: vazir;
    font-size: 8px;
    /*text-align: left;*/
    color: var(--clr-fg-alt);
}

.bNazanintdrb {
    font-family: vazir;
    /*font-size: 12px;*/
    /*text-align: left;*/
    color: var(--clr-fg-alt);
    font-weight: bold;

}

.hamed {
    font-family: hamed;
    font-size: 26px;
    color: var(--clr-fg-alt);
}

.lalezar {
    font-family: lalezar;
    font-size: 18px;
    color: var(--clr-fg-alt);
}

.lalezarI {
    font-family: lalezar;
    font-size: 10px;
    color: var(--clr-fg-alt);
}

.lalezarShop {
    font-family: lalezar;
    font-size: 14px;
    color: var(--clr-fg-alt);
    margin-bottom: 20px;
}

.lalezarLink {
    font-family: lalezar;
    font-size: 15px;
    color: var(--clr-fg-alt);
}

.lalezarLinkB {
    font-family: lalezar;
    font-size: 15px;
    color: #CDCDFFFF;
}

.vazir {
    font-family: vazir, sans-serif;
    font-size: 18px;
    color: var(--clr-fg-alt);
}

.vazirab {
    font-family: vazir, sans-serif;
    font-size: 16px;
    color: var(--clr-fg-alt);
    text-align: justify;
    direction: rtl;
}

.vazirablog {
    font-family: vazir, sans-serif;
    font-size: 12px;
    color: var(--clr-fg-alt);
    text-align: justify;
    direction: rtl;
}

.vazirlight {
    font-family: vazirlight;
    font-size: 18px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.vazirlightd {
    font-family: vazirlight;
    font-size: 12px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.sogand {
    font-family: sogand;
    font-size: 18px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.sogandUser {
    font-family: sogand;
    font-size: 24px;
    color: var(--clr-fg-alt);
    font-weight: bold;
}

.parastoobold {
    font-family: parastoobold;
}

.bTitr {
    font-family: titr, sans-serif;
    /*font-size: 18px;*/
    color: var(--clr-fg-alt);
    /*font-weight: bold;*/
}

.bTitrShop {
    font-family: titr;
    font-size: 18px;
    color: var(--clr-fg-alt);
    /*font-weight: bold;*/
}

.bTitrShopS {
    font-family: titr;
    font-size: 18px;
    color: var(--clr-primary);
    /*font-weight: bold;*/
}

.bTitrImm {
    font-family: titr;
    font-size: 24px;
    color: var(--clr-primary);
    /*font-weight: bold;*/
}

.bTitrBlog {
    font-family: titr;
    font-size: 21px;
    color: var(--clr-primary);
    /*font-weight: bold;*/
}
.app {
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    color: var(--clr-fg);
    background-color: var(--clr-bg);
}

.light {
    --clr-bg: #fcfcfc;
    --clr-bg-alt: #fff;
    --clr-fg: #555;
    --clr-fg-alt: #444;
    --clr-primary: #2978b5;
    --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
    --clr-bg: #23283e;
    --clr-bg-alt: #2a2f4c;
    --clr-fg: #bdbddd;
    --clr-fg-alt: #cdcdff;
    --clr-primary: #90a0d9;
    --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
    max-width: 1100px;
    width: 95%;
    margin: 0 auto;
}

.section {
    margin-top: 5em;
}

.section__title {
    text-align: center;
    margin-bottom: 1em;
    text-transform: uppercase;
    padding-top: 90px;
}

.center {
    display: flex;
    align-items: center;
}

.link {
    color: var(--clr-primary);
    padding: 0 0 0.3em 0;
    position: relative;
}

.link:hover {
    color: var(--clr-primary);
}

.link::before {
    content: '';
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: 0;
    background-color: var(--clr-primary);
    transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
    width: 100%;
}

.link--nav {
    color: var(--clr-fg);
    text-transform: lowercase;
    font-weight: 500;
}

.link--icon {
    color: var(--clr-fg);
}

.btn {
    display: block;
    cursor: pointer;
    padding: 0.8em 1.4em;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: lowercase;
    transition: transform 0.2s ease-in-out;
}

.btn--outline {
    color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.btn--outline:hover,
.btn--outline:focus {
    color: var(--clr-bg);
}

.btn--outline:before {
    content: '';
    position: absolute;
    background-color: var(--clr-primary);
    right: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: -1;
    transition: right 0.2s ease-in-out;
}

.btn--outline:hover:before,
.btn--outline:focus:before {
    right: 0;
}

.btn--plain {
    text-transform: initial;
    background-color: var(--clr-bg-alt);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    border: 0;
    width: 95px;
    height: 83px;
}

.btn--plain:hover {
    transform: translateY(-4px);
}

.btn--icon {
    padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
    color: var(--clr-primary);
}

.btn--icon:active {
    transform: translateY(-5px);
}

@media (max-width: 600px) {
    .section {
        margin-top: 4em;
    }
}


.scrollable-container {
    height: 100px;
    overflow-y: scroll;
}
.background {
    padding-top: 60px;
    height: 300px;
    background-image: url('https://zos.alipayobjects.com/rmsportal/RmjwQiJorKyobvI.jpg');
}


@keyframes wave {
    0%, 100% {
        color: red;
    }
    50% {
        color: blue;
    }
}

.waveText {
    display: inline-block;
    background: linear-gradient(90deg, red, orange, yellow, green, blue);
    -webkit-background-clip: text;
    color: transparent;
    animation: wave 3s infinite;
    background-size: 200% 100%;
    background-position: 0% 0%;
    animation: wave 5s linear infinite;
}

@keyframes wave {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}



.submit-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    opacity: 0.8;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
    position: relative;
}

.submit-button::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background-color: white; /* رنگ حاشیه */
    border-top-right-radius: 4px; /* تنظیم شعاع حاشیه برای گوشه بالا-راست */
    border: 2px solid #007bff; /* تنظیم حاشیه */
    z-index: -1; /* قرار دادن حاشیه در زیر دکمه */
}

.submit-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    opacity: 1;
}

.submit-button:active {
    background-color: #004494;
    transform: translateY(0);
    opacity: 0.9;
}

.rtl-marquee {
    direction: rtl;
    font-family: lalezarI, serif;
}


/*.custom-table {*/
/*    background-color: inherit;*/
/*}*/

/*.custom-thead th {*/
/*    font-size: 18px;*/
/*    font-weight: bold;*/
/*    text-align: center;*/
/*    padding: 10px;*/
/*    background-color: #f0f0f0;*/
/*}*/

.des {
    font-size: 25px;
    text-align: right;
    font-family: IranNastaliq;
}

.centered-table .ant-table-cell {
    text-align: center;
 }
/*div {*/
/*    text-align: center;*/
/*}*/
.ant-col.ant-form-item-label {
    text-align: right;
}

.ant-input.ant-input-status-success.bNazanintdr {
    text-align: left;
}

.ant-input ant-input-status-error bNazanintdr {
    text-align: left;
}
.signup-container {
    direction: rtl; /* برای راست‌چین کردن */
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
    max-width: 400px;
    margin: 50px auto;
    padding: 0px;
    text-align: right;
    display: flex; /* استفاده از Flexbox */
    flex-direction: column; /* چیدمان به صورت عمودی */
}

.signup-title {
    margin-bottom: 20px;
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
    text-align: right;
}

.signup-container .ant-form-item {
    text-align: right; /* راست‌چین کردن محتوا */
}

.signup-container .ant-input {
    direction: rtl; /* راست‌چین کردن پیش‌فرض ورودی‌ها */
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
}

.signup-container .phone-input::placeholder {
    text-align: right; /* راست‌چین کردن متن پیش‌فرض فیلد شماره تلفن */
}

.signup-container .phone-input {
    direction: ltr; /* چپ‌چین کردن متن وارد شده در فیلد شماره تلفن */
    text-align: left; /* چپ‌چین کردن متن وارد شده */
}

.signup-container .signup-btn {
    display: block; /* نمایش دکمه به صورت بلوک */
    margin: 0 auto; /* قرار دادن دکمه در مرکز */
    font-family: 'bNazanin', sans-serif; /* استفاده از فونت bNazanin */
}

.signup-container .phone-input::placeholder, .signup-container .nationalcode-input::placeholder, .signup-container .email-input::placeholder {
    text-align: right; /* راست‌چین کردن متن پیش‌فرض فیلدهای شماره تلفن و کد ملی */
}

.signup-container .phone-input, .signup-container .nationalcode-input, .signup-container .email-input {
    direction: ltr; /* چپ‌چین کردن متن وارد شده در فیلدهای شماره تلفن و کد ملی */
    text-align: left; /* چپ‌چین کردن متن وارد شده */
}

/* Signup.css */
.signup-btn-container {
    display: flex;
    justify-content: center;
}
/* EduShop.css */
.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 16px;
    gap: 16px;
    padding: 16px;
    direction: rtl; /* برای راست چین کردن کل گرید */
}

.product-card {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* سایه نازک */
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    text-align: right;
    padding: 10px;
}

.product-image {
    width: 100%;
    max-width: 350px;
    height: 220px; /* تنظیم ارتفاع ثابت */
    object-fit: cover; /* برای تنظیم تصاویر بزرگ */
    display: block;
    margin: 0 auto 16px;
}

.bNazanintdrD {
    text-align: justify; /* متن justify */
}

.bNazanintdrD4 {
    text-align: justify; /* متن justify */
    color: var(--clr-fg-alt);
}

.bNazanintdrDA {
    font-family: vazir;
    font-size: 12px;
    color: var(--clr-fg-alt);
    text-align: justify; /* متن justify */
    direction: rtl;      /* تنظیم جهت متن به سمت راست */
}

.more-button {
    color: #a9a9a9; /* رنگ خاکستری کمرنگ برای دکمه ... more */
    cursor: pointer;
    text-align: left;
    display: block;
    margin-top: 8px;
    font-size: 0.8rem; /* کاهش اندازه فونت */
    padding: 2px 5px;
}

.notification-rtl {
    direction: rtl; /* راست چین کردن نوتیفیکیشن */
    font-family: 'bNazanin', sans-serif; /* تنظیم فونت نوتیفیکیشن */
}


/* EduShop.css */
.notification-rtl {
    direction: rtl; /* راست چین کردن نوتیفیکیشن */
    font-family: 'bNazanin', sans-serif; /* تنظیم فونت نوتیفیکیشن */
}

.price {
    float: left; /* چپ‌چین کردن متن */
    margin-left: 10px; /* فاصله‌گذاری اختیاری برای زیبایی بیشتر */
    font-size: 0.7rem; /* کاهش اندازه فونت */
    color: rgba(0, 0, 0, 0.47); /* کاهش شدت رنگ */
}

.vertical-bar {
    font-weight: bold; /* برجسته کردن */
    font-size: 1rem; /* کاهش اندازه فونت برای کوچک‌تر شدن ارتفاع */
    color: rgba(0, 0, 0, 0.3); /* کمرنگ کردن */
}

.product-actions {
    padding-top: 20px;
}

.search-container {
    display: flex;
    justify-content: flex-end; /* Right align the search box */
    margin: 20px;
}

.search-input {
    padding: 8px;
    width: 100%; /* Responsive width */
    max-width: 320px;
    font-size: 16px;
    text-align: right;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    font-family: 'bNazanin', sans-serif; /* تغییر فونت */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .search-container {
        justify-content: center; /* Center align the search box on mobile */
    }
}
.coaching-path {
    padding: 5px;
    text-align: center;
}

.progress-bar {
    width: 5px;
    margin: 0 auto;
    background-color: #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.step {
    width: 200px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.step.left {
    align-self: flex-start;
    transform: translateX(-150%);
}

.step.right {
    align-self: flex-end;
    transform: translateX(150%);
}

.step.final {
    width: auto;
    max-width: none;
    transform: translateX(0);
    opacity: 1;
}

.step.visible {
    opacity: 1;
    transform: translateX(0) translateY(0);
}

.step h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.step p {
    font-size: 14px;
    margin: 0;
}

.signup-link {
    font-size: 12px;
    font-family: 'lalezar';
}

.scene-icon-e {
    display: flex;
    justify-content: center;
    align-items: center; /* اضافه کردن این خط برای قرارگیری عمودی */
    /*margin: 40px 0;*/
    /*height: 40px; !* ارتفاع دلخواه *!*/
}
body {
    text-align: right; /* راست چین کردن متن */
}

.avatar {
    width: 50%; /* عرض ۵۰٪ برای تصویر در دسکتاپ */
    height: auto;
    max-height: 370px; /* تنظیم حداکثر ارتفاع برای تصویر */
    margin-bottom: 20px;
    display: block;
    margin-left: 0; /* قرار دادن تصویر در سمت چپ */
    margin-right: 20px; /* فاصله بین تصویر و متن */
    border-top-left-radius: 50px; /* پخ کردن گوشه بالا سمت چپ */
    border-bottom-right-radius: 50px; /* پخ کردن گوشه پایین سمت راست */
}


.about-section {
    display: flex; /* استفاده از فلکس برای نمایش افقی */
    align-items: center; /* تنظیم تصویر و متن در وسط عمودی */
    background-color: var(--clr-bg);
    color: var(--clr-fg);
    padding: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; /* انعطاف‌پذیری در ریسپانسیو */
}

.about-text {
    flex: 1 1;
    margin-left: 20px; /* فاصله بین متن و تصویر */
}

.about-text h1 {
    color: var(--clr-primary);
    text-transform: uppercase;
    margin-bottom: 1em;
}

.about-text p {
    line-height: 1.6;
}

.toggle-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: var(--clr-primary);
    color: var(--clr-bg);
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

.toggle-button:hover {
    background-color: var(--clr-primary-dark);
}

.toggle-content {
    margin-top: 20px;
}

@media (max-width: 500px) {
    .avatar {
        width: 100%; /* عرض ۱۰۰٪ برای تصویر در دستگاه‌های ریسپانسیو */
        max-height: none; /* حذف محدودیت ارتفاع برای دستگاه‌های ریسپانسیو */
        margin-right: 0; /* حذف فاصله بین تصویر و متن در دستگاه‌های ریسپانسیو */
        margin-bottom: 20px; /* فاصله پایین برای تصویر در دستگاه‌های ریسپانسیو */
    }

    .about-section {
        flex-direction: column; /* تنظیم جهت نمایش به صورت عمودی در دستگاه‌های ریسپانسیو */
        align-items: center; /* تنظیم متن و تصویر در وسط */
    }

    .about-text {
        margin-left: 0; /* حذف فاصله بین متن و تصویر در دستگاه‌های ریسپانسیو */
        text-align: center; /* وسط چین کردن متن در دستگاه‌های ریسپانسیو */
    }
}

.contact-section {
    background-color: #f9f9f9; /* رنگ پس زمینه روشن */
    padding: 20px;
    border-radius: 10px; /* گوشه‌های گرد */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* سایه برای باکس */
    max-width: 600px; /* عرض حداکثر */
    margin: 20px auto; /* وسط چین کردن */
}

.contact-title {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center; /* وسط چین کردن عنوان */
}

.contact-text {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 13px;
    color: #555;
    margin-bottom: 15px;
    text-align: center; /* وسط چین کردن متن */
}

.contact-links {
    list-style: none; /* حذف نقاط لیست */
    padding: 0;
    text-align: center; /* وسط چین کردن لینک‌ها */
}

.contact-links li {
    display: inline-block;
    margin: 0 10px;
}

.contact-links a {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 16px;
    color: #0077b5; /* رنگ لینک‌ها */
    text-decoration: none; /* حذف خط زیر لینک‌ها */
}

.contact-links a:hover {
    text-decoration: underline; /* زیرخط دار کردن لینک‌ها هنگام هاور */
}

.toggle-text {
    display: block;
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 14px;
    color: #0077b5; /* رنگ لینک */
    text-align: center;
    cursor: pointer; /* نشانگر دست */
    margin-top: 15px;
    text-decoration: underline; /* زیرخط دار کردن متن */
}

.toggle-text:hover {
    color: var(--clr-fg-alt);
    font-size: 14px;
}

.custom-list {
    list-style-type: none; /* حذف نقاط پیش‌فرض لیست */
    text-align: right; /* راست‌چین کردن متن */
    padding-right: 20px; /* فاصله بین نقطه و متن */
    font-family: vazir;
    font-size: 12px;
}

.custom-list li {
    position: relative;
    padding-right: 20px; /* فاصله بین نقطه و متن */
    margin-bottom: 5px;
}

.custom-list li::before {
    content: '•'; /* علامت نقطه پرنگ */
    color: #333; /* رنگ نقطه */
    font-size: 20px; /* اندازه نقطه */
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1.2; /* ارتفاع خط برای وسط چین کردن نقطه */
}

.history-section {
    background-color: #f3f3f3; /* رنگ پس‌زمینه روشن */
    padding: 20px;
    border-radius: 10px; /* گوشه‌های گرد */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* سایه برای بخش */
    width: 100%; /* عرض 100 درصد */
    margin: 20px 0; /* حاشیه بالا و پایین */
    font-family: 'Vazir', sans-serif; /* فونت */
    color: #333; /* رنگ متن */
    text-align: right; /* راست‌چین کردن متن */
    box-sizing: border-box; /* برای در نظر گرفتن padding در عرض */
}

.history-section h2 {
    font-size: 24px;
    color: #0077b5; /* رنگ عنوان */
    margin-bottom: 15px;
    text-align: center; /* وسط چین کردن عنوان */
}

.bNazanintdrDA {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 12px; /* اندازه فونت */
    line-height: 1.6; /* فاصله بین خطوط */
    margin-bottom: 10px;
    text-align: justify; /* متن justify */
}




/* استایل‌های اصلی */
body {
    background-color: #f8f8f8; /* رنگ پس‌زمینه صفحه */
}

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white; /* رنگ پس‌زمینه هدر */
    border-bottom: 1px solid #e0e0e0; /* یک مرز ظریف برای تفاوت */
}

.logo {
    width: 130px; /* بزرگتر شدن لوگو */
}

.title-section {
    text-align: right;
}

.title {
    font-family: 'Lalezar', sans-serif;
    font-size: 2em;
    margin: 0 0 10px 0; /* اضافه کردن فاصله بین عنوان‌ها */
}

.subtitle {
    font-family: 'Lalezar', sans-serif;
    font-size: 1.5em;
    margin: 0 0 10px 0; /* اضافه کردن فاصله بین عنوان‌ها */
}

.description {
    font-family: 'Vazir', sans-serif;
    font-size: 1em;
    margin: 0 0 10px 0; /* اضافه کردن فاصله بین عنوان‌ها */
}

.table-section {
    padding: 20px;
    position: relative; /* برای موقعیت‌دهی نوار جستجو */
}

.search-bar {
    margin-bottom: 20px;
    width: 200px; /* کوچکتر کردن نوار جستجو */
    position: absolute;
    right: 0; /* قرار گرفتن در گوشه سمت راست */
    top: 10px; /* اضافه کردن فاصله از بالای بخش جدول */
    text-align: right; /* راست چین کردن متن داخل نوار جستجو */
}

.spacing {
    height: 40px; /* تنظیم ارتفاع برای ایجاد فاصله مناسب */
}

.center-align-table .ant-table-thead > tr > th {
    text-align: center; /* وسط چین کردن تایتل ستون‌ها */
    font-family: 'bTitrShopS', sans-serif; /* اعمال فونت Lalezar به تایتل ستون‌ها */
}

.center-align-table .ant-table-thead > tr > th,
.center-align-table .ant-table-tbody > tr > td {
    text-align: right; /* راست چین کردن متن داخل سلول‌های جدول */
    font-family: 'Vazir', sans-serif; /* اعمال فونت bNazanintN به داده‌ها */
}

.course-name-column {
    white-space: nowrap; /* جلوگیری از شکستن خط */
    overflow: hidden;
    text-overflow: ellipsis;
}

/* استایل‌های ریسپانسیو */
@media (max-width: 768px) {
    .header-section {
        flex-direction: column;
        text-align: center;
    }

    .logo {
        margin-bottom: 20px;
        width: 120px; /* تنظیم اندازه برای موبایل */
    }

    .title {
        font-size: 1.5em;
    }

    .subtitle {
        font-size: 1.2em;
    }

    .description {
        font-size: 0.9em;
    }

    .search-bar {
        width: 100%; /* تنظیم نوار جستجو برای موبایل */
        position: relative;
        right: auto;
        top: auto;
        margin-bottom: 10px;
    }

    .spacing {
        height: 20px; /* تنظیم ارتفاع برای ایجاد فاصله مناسب در موبایل */
    }

    /* حذف ستون‌های غیر ضروری در حالت ریسپانسیو */
    .ant-table-cell.hide-column {
        display: none;
    }
}

.footer-links {
    text-align: center;
    padding: 20px 0;
    margin-top: 20px; /* فاصله بین ایکون ها و ستون های جدول */
}

.footer-links .logo-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 20px; /* فاصله بین <td> ها */
}

.footer-links a {
    display: block;
    text-decoration: none;
    color: black;
    text-align: center;
}

.footer-links a:hover {
    color: blue;
}

.footer-links .logo-box {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5px;
    border: 1px solid #ccc;
}

.footer-links .logo-image {
    max-width: 50%;
    max-height: 50%;
}

.small-text {
    font-size: 0.8em;
}
/* Blog.css */

.header-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.container {
    display: flex;
    flex-wrap: wrap;
}

.sidebar {
    width: 20%;
    padding: 20px;
    background-color: #f4f4f4;
}

.content {
    width: 80%;
    padding: 20px;
    background-color: #fff;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 10px;
}

.sidebar ul li a {
    text-decoration: none;
    color: #333;
}

.sidebar ul li a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
    }

    .content {
        width: 100%;
    }
}

/* کلاس جدید برای راست‌چین کردن */
.right-align {
    text-align: left;
}

/*!* کلاس جدید برای تگ li *!*/
/*.justify-li {*/
/*    text-align: justify;*/
/*    !*font-family: bNazanintdr;*!*/
/*}*/


/* HeaderShop.css */
.notification-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1100;
    padding: 7px 10px;
    background-color: #fff4e5;
    border-bottom: 1px solid #ffd591;
    font-size: 10px;
    text-align: center;
}

.notification-bar a {
    color: #000;
    text-decoration: none;
    font-family: 'Vazir', sans-serif;
}

.notification-bar a:hover {
    text-decoration: underline;
}

.app-header {
    background-color: #fff;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 64px;
    direction: rtl;
    position: fixed;
    top: 25px;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

.logo-search-container {
    display: flex;
    align-items: center;
    flex: 1 1;
}

.logo-store {
    margin-right: 0px;
}

.search-section {
    display: flex;
    align-items: center;
    flex: 1 1;
}

.search-input-custom {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    text-align: right;
    direction: rtl;
    font-family: 'Vazir', sans-serif !important;
}

.search-section .ant-input::placeholder {
    font-family: 'Vazir', sans-serif !important;
}

.actions-section {
    display: flex;
    align-items: center;
    margin-right: auto;
    font-family: 'Vazir', sans-serif !important;
}

.actions-section .action-btn,
.actions-section .cart-icon,
.actions-section .ant-btn-link {
    color: #000000 !important;
}

.action-btn {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.action-btn .anticon {
    margin-right: 0;
}

.content {
    padding-top: 104px;
}

.store-name {
    font-family: 'Lalezar', sans-serif;
    font-size: 20px;
    color: #ff8c00;
    margin-left: 5px;
}

@media (max-width: 768px) {
    .app-header .logo-store {
        display: none;
    }

    .app-header {
        padding: 0 10px;
    }

    .logo {
        margin-right: 5px;
    }

    .search-section {
        flex: 1 1 100%;
        order: 2;
    }

    .actions-section {
        flex: 1 1 100%;
        justify-content: flex-end;
        order: 3;
    }

    .bottom-icons {
        display: flex;
        justify-content: space-around; /* تنظیم فاصله مساوی بین آیکون‌ها */
        align-items: center;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }

    .icon-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .actions-section {
        display: none;
    }

    .login-btn {
        display: flex;
        align-items: center;
        grid-gap: 5px;
        gap: 5px;
    }

    .login-btn .anticon {
        margin-right: 0;
    }

    .login-btn::after {
        content: none;
    }

    .mobile-categories {
        position: fixed;
        top: 89px; /* فاصله از بالای صفحه تا هدر */
        right: 0;
        width: 90px; /* عرض کوچک‌تر برای دسته‌بندی‌ها */
        height: calc(100% - 138px); /* ارتفاع با در نظر گرفتن هدر و فوتر */
        background-color: #efeff0;
        box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
        z-index: 900; /* تغییر z-index */
        padding-top: 20px; /* فاصله داخلی بالای صفحه */
        overflow-y: auto; /* فعال‌کردن اسکرول در صورت نیاز */
    }

    .category-item {
        width: 100%;
        text-align: right;
        padding: 10px 15px; /* فاصله داخلی دسته‌بندی‌ها */
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .category-icon {
        font-size: 24px; /* اندازه آیکون */
        color: #333; /* رنگ آیکون */
        margin-bottom: 5px; /* فاصله بین آیکون و نام دسته‌بندی */
    }

    .category-name {
        font-size: 14px; /* سایز فونت دلخواه */
        color: #333; /* رنگ متن دسته‌بندی */
    }
}

.search-results-list {
    position: absolute;
    top: 40px; /* فاصله از بالای کادر جستجو */
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 300px; /* تنظیم حداکثر ارتفاع */
    overflow-y: auto; /* فعال‌کردن اسکرول در صورت نیاز */
    direction: rtl;
    text-align: right;
    /* راست‌چین کردن متن */
    font-family: vazir, sans-serif;
}

.search-results-list .ant-list-item {
    padding: 10px;
    cursor: pointer;
}

.search-results-list .ant-list-item:hover {
    background-color: #f5f5f5;
}

.icon-label {
    font-family: 'IranianSans', sans-serif; /* استفاده از فونت IranianSans */
    font-size: 10px;
    margin-top: 4px; /* فاصله بین آیکون و متن */
    color: rgb(175, 176, 181);
}

.icon-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

/* HeaderShop.css */
.category-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px; /* ارتفاع دلخواه برای آیتم‌های لیست */
    font-family: vazir, sans-serif;
    font-size: 16px;
}

.category-icon {
    font-size: 15px; /* اندازه آیکون */
    color: #414750; /* رنگ آیکون */
    margin-bottom: 3px; /* فاصله بین آیکون و نام دسته‌بندی */
}

.category-name {
    color: #414750; /* رنگ متن دسته‌بندی */
    text-align: center; /* متن دسته‌بندی در مرکز */
    font-size: 10px;
}

/* ScrollToTopMobile.css */
.scroll-to-top {
    position: fixed;
    bottom: 55px; /* این مقدار را به دلخواه خود تنظیم کنید */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 24px;
    background-color: #ff8c00; /* رنگ پس‌زمینه دلخواه */
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.scroll-to-top:hover {
    background-color: #e58900; /* رنگ پس‌زمینه در حالت هاور */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.product-container {
    display: flex;
}

.product-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; /* تکمیل از سمت راست در حالت دسکتاپ */
    /*gap: 1px; !* فاصله کم بین سلول‌ها برای نمایش خطوط کم‌رنگ *!*/
    grid-gap: 0;
    gap: 0; /* تنظیم فاصله بین خانه‌های جدول به 0 */
    flex-grow: 1;
}

.product-cell {
    flex: 1 1 18%; /* کاهش عرض برای هر سلول */
    max-width: 18%; /* برای اطمینان از اندازه ثابت */
    box-sizing: border-box; /* اضافه کردن box-sizing برای محاسبه صحیح عرض */
    text-align: right; /* راست‌چین کردن متن‌ها */
    border: 1px solid #e0e0e0; /* خطوط کم‌رنگ سلول‌ها */
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.3s, border-color 0.3s; /* اضافه کردن انیمیشن برای افکت */
    position: relative; /* برای قرار دادن قیمت در سمت چپ پایین */
}

.product-cell:hover {
    background-color: #f0f0f0; /* تغییر رنگ پس‌زمینه هنگام حرکت ماوس */
    border-color: #1890ff; /* تغییر رنگ مرز هنگام حرکت ماوس */
}

.product-image {
    width: 100%;
    height: 300px; /* بازگرداندن ارتفاع تصاویر به مقدار قبلی */
    object-fit: cover; /* برای حفظ تناسب تصویر و پر کردن فضای موجود */
}

.product-info {
    margin-top: 10px;
    text-align: right; /* راست‌چین کردن اطلاعات محصول */
    font-family: 'Vazir', sans-serif; /* استفاده از فونت Vazir */
}

.product-info h3 {
    font-size: 12px; /* تنظیم سایز ۱۲ برای نام محصول */
}

.product-info .description {
    font-size: 10px; /* تنظیم سایز ۱۰ برای توضیحات محصول */
}

.product-rating {
    display: flex;
    align-items: center;
    font-size: 12px; /* تنظیم سایز ۱۲ برای ریتینگ */
    margin-top: 5px; /* تنظیم فاصله کمتر برای ریتینگ */
}

.product-rating i {
    color: #ffd700; /* رنگ طلایی برای ستاره */
    margin-right: 5px;
}

.product-price {
    font-size: 12px; /* تنظیم سایز ۱۲ برای قیمت */
    font-weight: bold; /* بولد کردن قیمت */
    position: absolute; /* قرار دادن قیمت در سمت چپ پایین */
    bottom: 10px;
    left: 10px;
    direction: rtl; /* تنظیم جهت متن به راست‌چین */
}

.filter-container {
    width: 200px; /* عرض فضای فیلتر */
    margin-left: 20px; /* فاصله از جدول محصولات */
    border: 1px solid #e0e0e0; /* خطوط کم‌رنگ فیلتر */
    padding: 10px;
}

/* تنظیمات رسانه‌ای برای صفحه نمایش‌های مختلف */

/* برای تبلت‌ها */
@media (max-width: 1024px) {
    .product-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end; /* تکمیل از سمت راست در تبلت‌ها */
        grid-template-columns: repeat(3, 1fr); /* کاهش تعداد ستون‌ها به ۳ در تبلت‌ها */
    }
}

/* برای موبایل */
@media (max-width: 768px) {
    .product-container {
        flex-direction: column;
    }

    .product-table {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; /* تکمیل از سمت چپ در موبایل */
    }

    .product-cell {
        flex: 1 1 100%; /* عرض کامل برای هر سلول در موبایل */
        max-width: 100%; /* عرض کامل برای هر سلول در موبایل */
        display: flex;
        flex-direction: row-reverse; /* تصویر به سمت راست و متن به سمت چپ */
        align-items: center;
        padding: 10px;
        width: 100%;
        box-sizing: border-box; /* اضافه کردن box-sizing برای محاسبه صحیح عرض */
        border-bottom: 1px solid #e0e0e0; /* اضافه کردن خط زیرین برای جداکردن محصولات */
        text-align: right; /* راست‌چین کردن متن‌ها در موبایل */
        height: 170px;
    }

    .product-image {
        width: 150px; /* افزایش عرض تصاویر */
        height: auto; /* تنظیم ارتفاع برای حفظ تناسب تصویر */
        margin-left: 10px; /* فاصله بین تصویر و متن */
    }

    .product-info {
        flex-grow: 1; /* افزایش فضای متن */
        text-align: right; /* راست‌چین کردن اطلاعات محصول */
        margin-top: 0;
    }

    .product-rating {
        margin-top: 10px; /* تنظیم فاصله بیشتر برای ریتینگ */
    }

    .product-price {
        margin-top: 10px; /* افزودن فاصله بین امتیاز و قیمت */
    }

    .filter-container {
        width: 100%; /* عرض فضای فیلتر در موبایل */
        margin: 10px 0; /* فاصله بالا و پایین از جدول محصولات */
    }
}

.shop-container {
    padding-top: 120px;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 0;
    gap: 0; /* فاصله بین کارت‌ها حذف شده */
    justify-items: center; /* برای تنظیم مکان کارت‌ها در مرکز خانه‌ها */
}

.product-card {
    width: 100%;
    text-align: right;
    border: 1px solid #e0e0e0;
    border-radius: 10px; /* گوشه‌ها به کارت اضافه شده */
    overflow: hidden; /* برای جلوگیری از ریختن محتوا از کارت */
}

.product-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.product-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.category-tabs {
    margin: 10px 0;
    font-family: Digikala, sans-serif;
}

/* برای موبایل */
@media (max-width: 768px) {
    .product-list {
        grid-template-columns: 1fr; /* تغییر به یک ستون در موبایل */
        grid-gap: 0;
        gap: 0; /* فاصله بین کارت‌ها حذف شده */
    }

    .product-card {
        display: flex;
        flex-direction: row-reverse; /* عکس سمت راست و توضیحات سمت چپ */
        align-items: center;
        padding: 10px;
        width: 100%; /* عرض کامل برای هر کارت در موبایل */
    }

    .product-card img {
        width: 200px; /* افزایش عرض تصاویر */
        height: auto; /* تنظیم ارتفاع به طور خودکار */
        margin-left: 10px; /* فاصله بین عکس و توضیحات */
    }

    .category-tabs {
        font-size: 12px;
    }
}

/* ProductDetail.css */
.product-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
}

.product-detail .ant-card {
    width: 600px;
    text-align: right;
}

.product-detail img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

