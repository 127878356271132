body {
    text-align: right; /* راست چین کردن متن */
}

.avatar {
    width: 50%; /* عرض ۵۰٪ برای تصویر در دسکتاپ */
    height: auto;
    max-height: 370px; /* تنظیم حداکثر ارتفاع برای تصویر */
    margin-bottom: 20px;
    display: block;
    margin-left: 0; /* قرار دادن تصویر در سمت چپ */
    margin-right: 20px; /* فاصله بین تصویر و متن */
    border-top-left-radius: 50px; /* پخ کردن گوشه بالا سمت چپ */
    border-bottom-right-radius: 50px; /* پخ کردن گوشه پایین سمت راست */
}


.about-section {
    display: flex; /* استفاده از فلکس برای نمایش افقی */
    align-items: center; /* تنظیم تصویر و متن در وسط عمودی */
    background-color: var(--clr-bg);
    color: var(--clr-fg);
    padding: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap; /* انعطاف‌پذیری در ریسپانسیو */
}

.about-text {
    flex: 1;
    margin-left: 20px; /* فاصله بین متن و تصویر */
}

.about-text h1 {
    color: var(--clr-primary);
    text-transform: uppercase;
    margin-bottom: 1em;
}

.about-text p {
    line-height: 1.6;
}

.toggle-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: var(--clr-primary);
    color: var(--clr-bg);
    border: none;
    cursor: pointer;
    border-radius: 8px;
}

.toggle-button:hover {
    background-color: var(--clr-primary-dark);
}

.toggle-content {
    margin-top: 20px;
}

@media (max-width: 500px) {
    .avatar {
        width: 100%; /* عرض ۱۰۰٪ برای تصویر در دستگاه‌های ریسپانسیو */
        max-height: none; /* حذف محدودیت ارتفاع برای دستگاه‌های ریسپانسیو */
        margin-right: 0; /* حذف فاصله بین تصویر و متن در دستگاه‌های ریسپانسیو */
        margin-bottom: 20px; /* فاصله پایین برای تصویر در دستگاه‌های ریسپانسیو */
    }

    .about-section {
        flex-direction: column; /* تنظیم جهت نمایش به صورت عمودی در دستگاه‌های ریسپانسیو */
        align-items: center; /* تنظیم متن و تصویر در وسط */
    }

    .about-text {
        margin-left: 0; /* حذف فاصله بین متن و تصویر در دستگاه‌های ریسپانسیو */
        text-align: center; /* وسط چین کردن متن در دستگاه‌های ریسپانسیو */
    }
}

.contact-section {
    background-color: #f9f9f9; /* رنگ پس زمینه روشن */
    padding: 20px;
    border-radius: 10px; /* گوشه‌های گرد */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* سایه برای باکس */
    max-width: 600px; /* عرض حداکثر */
    margin: 20px auto; /* وسط چین کردن */
}

.contact-title {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    text-align: center; /* وسط چین کردن عنوان */
}

.contact-text {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 13px;
    color: #555;
    margin-bottom: 15px;
    text-align: center; /* وسط چین کردن متن */
}

.contact-links {
    list-style: none; /* حذف نقاط لیست */
    padding: 0;
    text-align: center; /* وسط چین کردن لینک‌ها */
}

.contact-links li {
    display: inline-block;
    margin: 0 10px;
}

.contact-links a {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 16px;
    color: #0077b5; /* رنگ لینک‌ها */
    text-decoration: none; /* حذف خط زیر لینک‌ها */
}

.contact-links a:hover {
    text-decoration: underline; /* زیرخط دار کردن لینک‌ها هنگام هاور */
}

.toggle-text {
    display: block;
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 14px;
    color: #0077b5; /* رنگ لینک */
    text-align: center;
    cursor: pointer; /* نشانگر دست */
    margin-top: 15px;
    text-decoration: underline; /* زیرخط دار کردن متن */
}

.toggle-text:hover {
    color: var(--clr-fg-alt);
    font-size: 14px;
}

.custom-list {
    list-style-type: none; /* حذف نقاط پیش‌فرض لیست */
    text-align: right; /* راست‌چین کردن متن */
    padding-right: 20px; /* فاصله بین نقطه و متن */
    font-family: vazir;
    font-size: 12px;
}

.custom-list li {
    position: relative;
    padding-right: 20px; /* فاصله بین نقطه و متن */
    margin-bottom: 5px;
}

.custom-list li::before {
    content: '•'; /* علامت نقطه پرنگ */
    color: #333; /* رنگ نقطه */
    font-size: 20px; /* اندازه نقطه */
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1.2; /* ارتفاع خط برای وسط چین کردن نقطه */
}

.history-section {
    background-color: #f3f3f3; /* رنگ پس‌زمینه روشن */
    padding: 20px;
    border-radius: 10px; /* گوشه‌های گرد */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* سایه برای بخش */
    width: 100%; /* عرض 100 درصد */
    margin: 20px 0; /* حاشیه بالا و پایین */
    font-family: 'Vazir', sans-serif; /* فونت */
    color: #333; /* رنگ متن */
    text-align: right; /* راست‌چین کردن متن */
    box-sizing: border-box; /* برای در نظر گرفتن padding در عرض */
}

.history-section h2 {
    font-size: 24px;
    color: #0077b5; /* رنگ عنوان */
    margin-bottom: 15px;
    text-align: center; /* وسط چین کردن عنوان */
}

.bNazanintdrDA {
    font-family: 'Vazir', sans-serif; /* فونت */
    font-size: 12px; /* اندازه فونت */
    line-height: 1.6; /* فاصله بین خطوط */
    margin-bottom: 10px;
    text-align: justify; /* متن justify */
}

